import { createRouter, createWebHistory } from 'vue-router';
import configRouter from '../modules/config/router';
import planningRouter from '../modules/planning/router';
import collaboratorRouter from '../modules/collaborator/router';
import dataRouter from '../modules/data/router';
import authRouter from '../modules/auth/router';
import decaconvoRouter from '../modules/decaconvo/router';
import cmrstoreRouter from '../modules/cmrstore/router';
import { decrypt } from "@/modules/auth/auth";
import Cookies from 'js-cookie';

const routes = [
  {
    path: '/',
    redirect: '/collaborator'  
  },
  {
    path: '/config',
    ...configRouter
  },
  {
    path: '/planning',
    ...planningRouter
  },
  {
    path: '/collaborator',
    ...collaboratorRouter
  },
  {
    path: '/data',
    ...dataRouter
  },
  {
    path: '/login',
    ...authRouter
  },
  {
    path: '/decaconvo',
    ...decaconvoRouter
  },
  {
    path: '/cmrstore/:cmr?',
    ...cmrstoreRouter
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const cmrStore = to.params.cmr;
  if (cmrStore) {
    Cookies.set('cmrStore', cmrStore, { expires: 10 / 1440 });
  }
  const roles = decrypt("roles");
  // Forzar acceso solo a cmrstore para ROLE_USER_STORE
  if (roles === "ROLE_USER_STORE") {
    if (to.name !== 'CmrReceptionStore') {
      return next({ name: 'CmrReceptionStore' });
    }
  }
  else if (roles === "ROLE_USER" && !['collaborator', 'collaboratorByCmr', 'returnByCmr', 'controlDecaconvo', 'search', 'cmr', 'cmrByDestiny', 'cmrByCarrier', 'transport'].includes(to.name)) {
    return next({ name: 'collaborator' });
  } 
  else if (roles === "ROLE_USER_VADA" && !['collaborator', 'collaboratorByCmr', 'returnByCmr', 'controlDecaconvo', 'search', 'cmr', 'cmrByDestiny', 'cmrByCarrier', 'planning', 'transport'].includes(to.name)) {
    return next({ name: 'collaborator' });
  } 
  else if (roles !== "ROLE_IT_SUPER_ADMIN" && (to.name === 'center' || to.name === 'cmrCounter' || to.name === 'search' || to.name === 'cmrByDestiny' || to.name === 'cmrByCarrier')) {
    return next({ name: 'collaborator' });
  } 
  else if (roles === "null" && to.name !== 'loginView') {
    localStorage.removeItem('authenticated');
    return next({ name: 'loginView' });
  }

  next();
});


export default router;
