import planningApi from "@/api/PlanningApi"
import Swal from "sweetalert2"
import{i18n}  from "@/main.js"
import { responseErrorText } from "@/helpers/Parser";

export const getPlanningsByVada = (state, getters, rootState) => async (yearWeek, planningFilter) => {
    Swal.fire({
      title: i18n.global.t("loading"),
      allowOutsideClick: false,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#6e7d88',
    });
    Swal.showLoading();
  
    state.plannings = [];
    state.planningsWeek = [];
  
    try {
      const vada = rootState.vada;

      // Check format week
      const regexWeek = /(\d{4}-W\d{1,2})/;
      if(!regexWeek.test(yearWeek)) {
        throw new Error("Error format week");
      }

      // Configura los parámetros de la solicitud
      const params = {
        year: yearWeek.substring(0, 4),
        week: yearWeek.substring(6),
      };
  
      // Check if any filter
      if(planningFilter != null)
      {
        params.day = planningFilter.day??null;
        params.closing = planningFilter.closing??null;
        params.orderType = planningFilter.orderType??null;
        params.truckType = planningFilter.truckType??null;
        params.carrier = planningFilter.carrier??null;
        params.destiny = planningFilter.destiny??null;
        params.loadOrder = planningFilter.loadOrder??null;
        params.loadHour = planningFilter.loadHour??null;
      }
  
      const getResponse = await planningApi.get(`/api/v1/plannings/vada/${vada}`, { params });
      if (getResponse.status === 200) {
        const result = [...getResponse.data];
        state.plannings = result;
        state.planningsWeek = result;
        state.isLoading = false;
        Swal.close();
      }
    } catch (err) {
      state.isLoading = false;
      Swal.fire({
        icon: "error",
        title: `Error while getting the plannings`,
        text: responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
        confirmButtonColor: "#414DBC",
        cancelButtonColor: "#6e7d88"
      });
    }
  };


export const filterPlanningByDestiny = (state) => (destiny) => {
    if (destiny.toUpperCase() == "") {
        state.plannings = state.planningsWeek;
        return;
    }
    const plannings = [];
    state.planningsFilter = [...state.plannings];
    
    state.planningsFilter.map(p => {
        let i = 0;
        p.planning_destiny.destinations.map(d => {
            if (d.destiny.name.toUpperCase().includes(destiny.toUpperCase())) {
                i++;
            }
        });
        if (i) {
            p.planning_destiny.destinations.sort((a, b) => {
                const hourA = a.hour_download || '';
                const hourB = b.hour_download || '';
                if (hourA < hourB) return -1;
                if (hourA > hourB) return 1;
                return 0;
            });
            plannings.push(p);
        }
    });
    state.plannings = [...plannings];
};

export const getPlanningsByDates = (state,getters,rootState) => async (week1, week2) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    const vada = rootState.vada
    const path = `/api/v1/planningsByDates?week1=${(week1)}&week2=${week2}&vada=${vada}`
    const { data } = await planningApi.get(path)
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting plannings by dates`,
            text:  responseErrorText(err, i18n.global.t('tryAgainContactAdmin')),
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
        
    })   
    Swal.close()
    return data;
}

