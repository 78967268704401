export const setCenters = (state, data) => {
    state.centers = [...data]
}

export const deleteCenter = (state, reference) => {
    const centers = state.centers.filter(c => c.reference !== reference)
    state.centers = [...centers]
}

export const setCarriers = (state, data) => {
    state.carriers = [...data]
}

export const deleteCarrier = (state, reference) => {
    const carriers = state.carriers.filter(c => c.reference !== reference)
    state.carriers = [...carriers]
}

export const deleteOrderType = (state, reference) => {
    const ordertypes = state.ordertypes.filter(o => o.reference !== reference)
    state.ordertypes = [...ordertypes]
}

export const deleteTruckType = (state, reference) => {
    const trucktypes = state.trucktypes.filter(o => o.reference !== reference)
    state.trucktypes = [...trucktypes]
}

export const deleteDestiny = (state, reference) => {
    const destinies = state.destinies.filter(d => d.reference !== reference)
    state.destinies = [...destinies]
}

export const deleteInvoice = (state, reference) => {
    const invoices = state.invoices.filter(i => i.reference !== reference)
    state.invoices = [...invoices]
}

export const deleteReception = (state, reference) => {
    const receptions = state.receptions.filter(r => r.reference !== reference)
    state.receptions = [...receptions]
}

export const deleteCmrCounter = (state, id) => {
    const cmrCounters = state.receptions.filter(c => c.id !== id)
    state.cmrCounters = [...cmrCounters]
}

export const deleteFuel = (state, id) => {
    const fuels = state.fuels.filter(c => c.id !== id)
    state.fuels = [...fuels]
}

export const deletePackaging = (state, id) => {
    const packaging = state.packaging.filter(f => f.id !== id)
    state.packaging = [...packaging]
}

export const editMode = (state, value) => {
    state.editMode = value
}

export const changeVada = (state, value) => {
    state.vada = value
}